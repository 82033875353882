.container-widgets {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
}

.home-page-land {
}

.ver-todas {
    display: flex;
    justify-content: right;
}

.flex-carousel {
    display: flex;
    justify-content: start;
    gap: 1.8rem;
    flex-wrap: wrap;
}

.widgets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    row-gap: 20px;
}

@media screen and (min-width: 856) {
    .widgets {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 576px) {
    .user-card {
        grid-column: 1 / -1;
    }
    .widgets {
        grid-template-columns: 1fr;
        margin-top: 2rem;
    }
    .homeCards-text {
        text-align: center;
    }
    .flex-carousel {
        justify-content: center;
    }
    .ver-todas {
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .user-card {
        grid-column: 1 / -1;
    }
    .widgets {
        grid-column: 1 / -1;
    }
}


@media (min-width:576px) {
    .Lg-mb-5{
        margin-bottom: 3rem;
    }
    
}
