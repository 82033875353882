.btn-scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0.8;
    z-index: 9999;
}

.btn-scroll-to-top:hover {
    opacity: 1;
}
