/* The switch - the box around the sliderChatLink */
.switchChatLink {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 1.5rem;
  margin: 0;
  min-width: 64px
}

/* Hide default HTML checkbox */
.switchChatLink input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

/* The sliderChatLink */
.sliderChatLink {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #03273c;
  -webkit-transition: .4s;
  transition: .4s;
  margin: 0;
}

.sliderChatLink:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switchChatLink input:checked + .sliderChatLink {
  background-color: #2196F3;
}

.switchChatLink input:focus + .sliderChatLink {
  box-shadow: 0 0 1px #2196F3;
}

.switchChatLink input:checked + .sliderChatLink:before {
  -webkit-transform: translateX(1.9rem);
  -ms-transform: translateX(1.9rem);
  transform: translateX(2.3rem);
}

/* Rounded sliders */
.sliderChatLink.roundChatLink {
  border-radius: 1.1rem;
}

.sliderChatLink.roundChatLink:before {
  border-radius: 50%;
}