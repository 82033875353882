.card {
    display: flex;
    min-width: min-content;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    container-type: inline-size;
    justify-content: space-evenly;
    gap: 0.5vw;
}
.container {
    container-type: inline-size;
}

.cubetext {
    display: flex;
}

.text {
    /* border: 2px solid blue !important; */
    font-size: 0.8rem;
    min-width: 104px;
    font-size: clamp(0.5rem, 0.8rem, 1rem) !important;
    max-width: 70%;
}

.text h5 {
    font-size: clamp(0.5rem, 0.8rem, 1rem) !important;
}
.button {
    /* border: 2px solid orange !important; */
    height: 50%;
    transition: 0.2s ease-in-out;
    padding: 0.3em 0.6em;
}
.button div:hover {
    box-shadow: 0 0 2px 0.1em #eaeaea;
    scale: 1.02;
    transition: 0.2s ease-in-out;
}
.button div:active {
    scale: 0.98;
}

.separator {
    border: 1px solid #ccc;
    background: aliceblue;
    width: 100%;
    height: 1px;
    position: relative;
}

.copied {
    color: brown !important;
    border: 1px brown solid !important;
}

@media (max-width: 768px) {
    .card {
        align-items: center !important;
        justify-content: center !important;
    }
    .container {
    }
}

@media (min-width: 1px) {
    .card {
        display: grid;
        grid-template-columns: 64px minmax(104px, 2fr) 1px 1fr;
        grid-auto-rows: auto;
        align-content: center;
        gap: 0.2rem;
        align-items: center;
        padding: 0 1em !important;
    }
    .container {
        min-width: 244px !important;
    }
    .card .separator {
        width: 1px !important;
        min-height: 2rem;
        height: clamp(2rem, 4rem, 5rem);
        position: relative;
    }
}
