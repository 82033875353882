.card {
    align-items: center;
    text-align: center;
    container-type: inline-size;
    gap: 8px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 1rem;
}
.container {
    container-type: inline-size;
}

.cubetext {
    display: flex;
}


.text {
    /* border: 2px solid blue !important; */
    font-size: 0.8rem;
    min-width: min-content;
    font-size: clamp(0.5rem, 0.8rem, 1rem) !important;
    max-width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 576px) {
    /* .text,
    .separator {
        margin-right: -20%;
    } */
    .title{
        min-width: 177px;
    }
}

.text p {
    margin-bottom: 0 !important;
    min-width: 113px;
}
body[data-layout-mode="dark"] .button {
    /* border: 2px solid orange !important; */
    height: 50%;
    transition: 0.2s ease-in-out;
}
body[data-layout-mode="dark"] .button div:hover {
    box-shadow: 0 0 1em 0.1em #eaeaea;
    scale: 1.05;
    transition: 0.2s ease-in-out;
}
body[data-layout-mode="dark"] .button div:active {
    box-shadow: 0 0 1em 0.1em #eaeaea;
    scale: 0.95;
    transition: 0.2s ease-in-out;
}

body[data-layout-mode="light"] .button {
    /* border: 2px solid orange !important; */
    height: 50%;
    transition: 0.2s ease-in-out;
}
body[data-layout-mode="light"] .button div:hover {
    box-shadow: 0 0 1em 0.1em #038edc;
    scale: 1.05;
    transition: 0.2s ease-in-out;
}
body[data-layout-mode="light"] .button div:active {
    box-shadow: 0 0 1em 0.1em #038edc;
    scale: 0.95;
    transition: 0.2s ease-in-out;
}

body[data-layout-mode="dark"] .separator {
    width: 1px !important;
    min-height: 2rem;
    height: clamp(2rem, 4rem, 5rem);
    position: relative;
    border: 1px snow solid;
}

body[data-layout-mode="light"] .separator {
    width: 1px !important;
    min-height: 2rem;
    height: clamp(2rem, 4rem, 5rem);
    position: relative;
    border: 1px #038edc solid;
}

.copied {
    color: brown !important;
    border: 1px brown solid !important;
}

@container (min-width: 100px) {
    .card {
        container-type: inline-size;
        justify-content: space-between;
        width: 100%;
    }
}

@container (min-width: 80px) {
    .card .separator {
        width: 1px !important;
        min-height: 2rem;
        height: clamp(2rem, 4rem, 5rem);
        position: relative;
    }
}
