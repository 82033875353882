/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.5em;
    margin: 0;
    margin-right: 5px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    --background: #03273c;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background);
    transition: 0.5s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    border-radius: 50%;
    left: 10%;
    bottom: 15%;
    box-shadow: inset 8px -4px 0px 0px #fff000;
    background: var(--background);
    transition: 0.5s;
}

input:checked + .slider {
    background-color: #eaeaea;
}

input:checked + .slider:before {
    transform: translateX(150%);
    box-shadow: inset 15px -4px 0px 15px #fff000;
}
