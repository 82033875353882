.container-details-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 90px 90px 90px;
    grid-column-gap: 60px;
    grid-row-gap: 10px;
}

.container-details-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 90px;
    grid-column-gap: 60px;
    grid-row-gap: 2rem;
    padding: 0 1rem;
    // border: 1px solid red !important;
}

.data {
    display: grid;
    grid-template-rows: auto 1fr;
}

.data,
.data > input {
    text-align: center;
}

.data-large {
    grid-column: 1/-1;
}

.form-control {
    &.light {
        background-color: #f5f6f8;
    }
    height: auto;
    word-break: break-word;
}

@media (max-width: 576px) {
    .container-details-2,
    .container-details-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 90px;
        grid-column-gap: 1rem;
        grid-row-gap: 2rem;
        padding: 0 0rem;
        // border: 1px solid red !important;
    }
    .modal-card {
        padding: 1rem 1rem !important;
    }
    .data {
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
    .data > input {
        font-size: 0.8rem;
    }
}

@media (min-width: 576px) {
    .modal-card {
        padding: 1rem 2rem !important;
    }
}
