@import "../../icons.scss";

// Light Theme

// Variables
$base-verde: #70b28e;
$color-error: rgb(207, 77, 89);
$verde-oscuro: #3e5949;

.toast-custom {
    position: absolute;
    border-radius: 12px;
    top: 135px;
    right: 20px;
    background: #032d46;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 35px 20px 25px;
    z-index: 1;
    overflow: hidden;
    height: 7rem;
    transform: translateX(calc(100% + 20px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.toast-custom .closeIcon {
    margin: 0.2rem;
    cursor: pointer;
    border-radius: 50%;
    color: #79b891;
    stroke: #51d28c;
    width: 1.3em;
    height: 1.3em;
    // background: $base-verde escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
}

.toast-custom .closeIcon:hover {
    scale: 1.05;
}
.toast-custom .closeIcon:active {
    scale: 0.98;
}

.closeIconContainer {
    background-color: $verde-oscuro;
    display: grid;
    place-content: center;
    width: 1.6em !important;
    height: 1.6em !important;
    position: absolute;
    top: 16%;
    right: 3%;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    // background-color: #f34e4e;
}
.toast-custom.success {
    border-left: 1px solid $base-verde;
    border-top: 1px solid $base-verde;
    border-right: 1px solid $base-verde;
    border-bottom: 1px solid $base-verde;
}

.toast-custom.error {
    border-left: 1px solid $color-error;
    border-top: 1px solid $color-error;
    border-right: 1px solid $color-error;
    border-bottom: 1px solid $color-error;
    color: rgb(207, 77, 89);
}

.toast-custom.error .closeIconContainer {
    background-color: rgba(207, 77, 90, 0);
}
.toast-custom.error .closeIcon {
    stroke: rgb(207, 77, 89);
    background-color: #032e4600;
}

.toast-custom.errorWithdraw {
    border-left: 1px solid $color-error;
    border-top: 1px solid $color-error;
    border-right: 1px solid $color-error;
    border-bottom: 1px solid $color-error;
    color: rgb(207, 77, 89);
}

.toast-custom.errorWithdraw .closeIconContainer {
    background-color: rgba(207, 77, 90, 0);
}
.toast-custom.errorWithdraw .closeIcon {
    stroke: rgb(207, 77, 89);
    background-color: #032e4600;
}
.errorText {
    color: #f34e4e;
}

.toast-custom.active {
    transform: translateX(0%);
}

.toast-custom .toast-custom-content {
    display: flex;
    align-items: center;
    height: 100%;
}
.exito {
    color: $base-verde;
}
.checkIcon {
    width: 1.5em;
    height: 1.5em;
    stroke: $base-verde;
}
.IconCheckContainer {
    background: $verde-oscuro;
    padding: 1em;
    width: 3em;
    height: 3em;
    display: grid;
    place-content: center;
    border-radius: 50%;
}
.toast-custom-content .message-custom {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    max-width: 372px;
}

.message-custom .text {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.text.text-1 {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    max-width: 300px;
}

.toast-custom .closeIcon:hover {
    opacity: 1;
}

.toast-custom .progress-custom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #032d46;
}

.toast-custom .progress-custom:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.toast-custom .progress-custom.success:before {
    background-color: #51d28c;
}
.toast-custom .progress-custom.error:before {
    background-color: #f34e4e;
}

.progress-custom.active:before {
    animation: progress 5s linear forwards;
}

@keyframes progress {
    100% {
        right: 100%;
    }
}
