.dotList {
    display: inline-block;
    margin: 0;
    padding: 10px 0;
    list-style-type: none;
}
.dotList:hover .dot {
    width: 8px;
    max-width: 8px;
    height: 8px;
    max-height: 8px;
    margin-right: 12px;
}
.dotList .dot {
    display: inline-block;
    width: 4px;
    max-width: 4px;
    height: 4px;
    max-height: 4px;
    margin-right: 3px;
    border-radius: 50%;
    background-color: #d2d2d2;
    transition: all 0.25s;
    cursor: pointer;
}
.dotList .dot.active {
    background-color: #e73439;
}
.dotList .dot:last-child {
    margin-right: 0;
}
.slides {
    display: flex;
    justify-content: center;
    align-items: center;
}
.list {
    display: flex;
    padding: 0;
    list-style-type: none;
}
.list > * {
    margin-right: 32px;
}
.list > *:last-child {
    margin-right: 0;
}
.navButtons {
    width: 120px;
    height: 120px;
    margin: 0px 10px;
    cursor: pointer;
}
.dotsControls {
    display: block;
    text-align: center;
}
