.wallet-container-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: start;
    color: #fff;
}

@media screen and (max-width: 619px) {
    .wallet-container-header {
        grid-template-columns: 1fr;
        .w-header-button {
            width: 100%;
        }
        display: flex;
        flex-direction: column;
    }
    // .wallet-container-header > div {
    //     margin-bottom: 14px;
    // }
}
@media screen and (min-width: 620px) {
    .wallet-container-header {
        grid-template-columns: 1fr 1fr;
    }
    .wallet-container-header > div {
        margin-bottom: 13px;
        column-span: 2;
    }
}

@media screen and (min-width: 920px) {
    .wallet-container-header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: auto;
        align-items: start;
        color: #fff;
    }
}

.wallet-texts-1 p {
    margin: 0;
}

.wallet-texts-1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wallet-texts-2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wallet-texts-2 > * {
    margin: 0;
}
.noDisplay{
    display: none;
}

.vmvisible{
    display: block;
}


.VerMas1{
    @media (max-width:620px) {
        display: none;
    }
}

.VerMas2{
    @media (min-width:620px) {
        display: none;
    }
}


.VerMenos{
    @media (max-width:620px) {
        display: none;
    }
}