.bootstrap-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #476585;
}

.bootstrap-btn:hover {
    background-color: #395068;
}

.loaded {
    background-color: #007bff;
}

.loaded:hover {
    background-color: #005aba;
}
