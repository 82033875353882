//
// _footer.scss
//

.footer {
    margin-top: 2rem;
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    left: 250px;
    // height: $footer-height;
    box-shadow: 0 0px 4px rgba(15, 34, 58, 0.12);
    display: flex;
    flex-direction: column-reverse;
    gap: 1.4rem;
}
.footer-links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media (min-width: 576px) {
    .footer {
        flex-direction: row;
        justify-content: space-around;
    }
    .footer-links {
        flex-direction: row;
        gap: 1rem;
        justify-content: space-evenly;
    }
}

@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
body[data-sidebar-size="sm"] {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}

body[data-layout-mode="dark"] {
    .footer {
        box-shadow: 0 0px 4px rgba($gray-dark-600, 0.12);
        color: $gray-dark-400;
    }
}
