
//
// choices
//

.choices__inner{
  padding: 0.25rem ($form-select-padding-x + $form-select-indicator-padding) 0.25rem 0.5rem;
  background-color: $form-select-bg;
  vertical-align: middle;
  border-radius: $form-select-border-radius;
  border: $form-select-border-width solid $form-select-border-color;
  min-height: 38px;
}


.choices[data-type*=select-one]{
  .choices__inner{
    padding-bottom: 0.25rem;
  }

  &:after{
    border: 0;
    border-color: $dark transparent transparent;
    right: 11.5px;
    left: auto;
    content: "\F0140";
    font-family: 'Material Design Icons';
    width: auto;
    height: auto;
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
  } 

  &.is-open:after{
    margin-top: 0;
    transform: translateY(-50%) rotate(-180deg);
  }

  .choices__button{
    right: 0;
    left: auto;
    margin-right: 25px;
    margin-left: 0;
  }
}

.choices__input{
  background-color: $input-bg;
  margin-bottom: 0;
}

.choices__list--multiple{
  display: inline-flex;
  flex-wrap: wrap;
  gap: 6px;
  .choices__item{
    background-color: $primary;
    border-color: $primary;
    margin-bottom: 0;
    margin-right: 0;
    font-weight: $font-weight-normal;
    &.is-highlighted{
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
}

.choices.is-disabled {
  .choices__inner, .choices__input{
    background-color: $light;
  }
}

.is-open, .is-focused{
  .choices__inner{
    border-color: $input-focus-border-color;
  }
}

.is-disabled .choices__list--multiple {
  .choices__item{
    background-color: $secondary;
    border-color: $secondary;
  }
}

.choices[data-type*=select-multiple], .choices[data-type*=text]{
  .choices__button{
    border-left: 1px solid rgba($white, 0.5);
    margin: 0 -4px 0 8px;
    border-right: 0;
  }
}

.choices__list--dropdown{
  border-color: $dropdown-border-color;
  box-shadow: $dropdown-box-shadow;
  .choices__item--selectable.is-highlighted{
    background-color: $dropdown-link-hover-bg;
  }
}

.is-open{
  .choices__list--dropdown{
    border-color: $dropdown-border-color;
  }
}


// Dark layout

body[data-layout-mode="dark"] {
  .choices__inner{
    color: $gray-dark-500;
    background-color: lighten($gray-dark-200,2%);
    border: 1px solid $gray-dark-300;
  }

  .choices__input{
    background-color: lighten($gray-dark-200,2%);
    color: $gray-dark-500;
    &::placeholder{
      color: $gray-dark-400;
    }
  }

  .choices[data-type*=select-one]{
    &:after{
      border-color: $gray-dark-500 transparent transparent;
    } 
  
    &.is-open:after{
      border-color: transparent transparent $gray-dark-500;
    }

    .choices__input{
      background-color: lighten($gray-dark-200,2%);
      border: 1px solid $gray-dark-300;
      color: $gray-dark-500;
    }
  }

  .choices__list--dropdown{
    background-color: lighten($gray-dark-200,2%);
    border-color: $gray-dark-300;

    .choices__item--selectable{
      &.is-highlighted{
        background-color: $gray-dark-300;
      }
    }
  }

  .choices.is-disabled {
    .choices__inner, .choices__input{
      background-color: lighten($gray-dark-200,2%);
    }
  }
}

// select2

[data-layout-mode="dark"]{
  .select2-selection__control, .select__control{
    color: $gray-dark-500;
    background-color: lighten($gray-dark-200,2%);
    border: 1px solid $gray-dark-300;
    &:hover{
      border-color: $gray-dark-300;
    }
  }

  .select2-selection__indicator-separator, .select__indicator-separator{
    background-color: $gray-dark-300;
  }

  .css-1wa3eu0-placeholder{
    color: $gray-dark-500;
  }

  .select2-selection__indicator{
    color: $gray-dark-500 !important;
  }

  .select2-selection__single-value, .select__multi-value__label, .select2-selection__multi-value__label{
    color: $gray-dark-500;
  }

  .select2-selection__menu, .select__menu{
    background-color: $gray-dark-300 !important;
  }

  .select__multi-value, .select2-selection__multi-value{
    background-color: $gray-dark-300;
  }

  .select2-selection{
    &.form-control__control{
      color: $gray-dark-500;
      background-color: lighten($gray-dark-200,2%);
      border-color: $gray-dark-300;
      &:hover{
        border-color: $gray-dark-300;
      }
    }

    .form-control__indicator-separator{
      background-color: $gray-dark-300;
    }

    .form-control__multi-value{
      background-color: $gray-dark-300;
    }

    .form-control__multi-value__label{
      color: $gray-dark-500;
    }

    &.form-control__menu{
      background-color: $gray-dark-300;
    }
  }
}