body[data-topbar="dark"] .logo-lg img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

body[data-topbar="dark"] .logo-sm img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

body[data-topbar="dark"] .auth-logo img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
}

body[data-topbar="dark"] .auth-logo img {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
