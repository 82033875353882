.container-widgets-campaing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.6rem;
}

@media screen and (max-width: 576px) {
    .widget {
        grid-column: 1 / -1;
    }
}
@media screen and (max-width: 767px) {
    .col {
        margin-top: 1rem !important;
    }
}

@media screen and (max-width: 900px) {
    .container-widgets-campaing {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 500px) {
    .container-widgets-campaing {
        grid-template-columns: 1fr;
    }
}

.container-widgets-wallet {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0.2rem;
    margin-bottom: 1rem;
    min-width: 285px;
}
@media screen and (max-width: 1000px) {
    .container-widgets-wallet {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 620px) {
    .container-widgets-wallet {
        grid-template-columns: 1fr;
    }
}
