body[data-layout-mode="dark"] {
    background-color: darken($gray-dark-200, 2%);
    color: $gray-dark-400;

    .light {
        color: $white;   
    }

    .h1,.h2,.h3,.h4,.h5,.h6,.p
    h1,h2,h3,h4,h5,h6,p {
        color: $gray-dark-600;
    }
}