  .react-draggable, .cursor {
    cursor: move;
  }
  .no-cursor {
    cursor: auto;
  }
  .cursor-y {
    cursor: ns-resize;
  }
  .cursor-x {
    cursor: ew-resize;
  }

  .react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
  }

  .box {
    background: #032d46;
    border-radius: 3px;
    width: 319px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
  }
  .no-pointer-events {
    pointer-events: none;
  }
  .hovered {
    background-color: gray;
  }

  .navBarSim{
    width: 100%;
    border-bottom: 1px solid ;
    // padding: 0.5rem 2rem;
    border-color: #043a5a;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navBarSim p {
    margin: 0;
  }
  .dots{
    display: flex;
    gap: 3px;
    padding-right: 1rem;
  }

  .dot{
    background-color: #f7f8fa;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 1px black);

  }

.windowBg{
  background: #f7f8fa;
}

  body[data-layout-mode="dark"] {
    .navBarSim{
      background-color: #043a5a;
      
    }
    .navText {
      color: #f7f8fa ;
    }

    .windowBg{
      background: #03273c;
    }
  }
  .navText {
    color: #f7f8fa ;
    background-color: #043a5a;
    padding: 1rem 2rem;
  }
  
  
  .dragText{
    color: #f7f8fa;
    margin: 14px 1.5rem;
  }

  @media (max-width:576px) {
      
  .dots{
    display: none;
  }
  .navText{
    min-width: 820px;
    padding: 1rem 2rem;
  }

    
  }

  @media (max-width:419px) {
      
    
    .dragDesktop{
      display: none;
    }
      
    }


  @media (min-width:420px) {
      .mobile{
        display: none;
      }

      
    }

    .dragDesktop::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    