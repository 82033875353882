.available-campaign {
    overflow: hidden;
    padding: 5px;
}

.available-campaign::before {
    content: "";
    pointer-events: none;
}

// .available-campaigns-container {
//     display: grid !important;
//     grid-template-columns: (repeat(minmax(150px, 1fr))) !important;
//     grid-template-rows: auto !important;
// }

.available-campaign span.move-lines {
    transition: 0.5s;
    opacity: 0;
}

.available-campaign:hover span.move-lines {
    opacity: 1;
}

.available-campaign:hover span.lines {
    opacity: 0;
}

.available-campaign span.lines {
    opacity: 1;
}

.available-campaign span.lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #048edc, #a231d7);
}

.available-campaign:hover span.lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    background: none;
    display: none;
}

.available-campaign span.lines:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to top, #048edc, #a231d7);
}
.available-campaign span.lines:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to left, #048edc, #a231d7);
}
.available-campaign span.lines:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to bottom, #048edc, #a231d7);
}

.available-campaign span.move-lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #048edc, #a231d7);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.available-campaign span.move-lines:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #048edc, #a231d7);
    animation: animate2 2s linear infinite;
    //animation-delay: 1s;
}

@keyframes animate2 {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}

.available-campaign span.move-lines:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to left, #048edc, #a231d7);
    animation: animate3 2s linear infinite;
}

@keyframes animate3 {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.available-campaign span.move-lines:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to top, #048edc, #a231d7);
    animation: animate4 2s linear infinite;
    //animation-delay: 1s;
}

@keyframes animate4 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

@media screen and (max-width: 619px) {
    .available-campaigns-container {
        justify-content: center;
    }
}
