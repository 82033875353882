
.Card {
  display: inline-block;
}

.ft{
  min-width: fit-content;
  max-width: 406px;
}

.option-cards-container{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.activeSelection{
  background: #015b8b !important ;
  color: white;
  
}
@media (max-width:576px) {

.option-cards-container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.accept-text {
  white-space: break-spaces;
}
}