.home-campaign {
    overflow: hidden;
    padding: 5px;
}

.home-campaign::before {
    content: "";
    pointer-events: none;
}

.home-campaign span.move-lines {
    transition: 0.5s;
    opacity: 0;
}

.home-campaign:hover span.move-lines {
    opacity: 1;
}

.home-campaign:hover span.lines {
    opacity: 0;
}

.home-campaign span.lines {
    opacity: 1;
}

.home-campaign span.lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #00ff06, #ff8813);
}

.home-campaign:hover span.lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    background: none;
    display: none;
}

.home-campaign span.lines:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to top, #00ff06, #ff8813);
}
.available-campaign span.lines:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to left, #00ff06, #ff8813);
}
.home-campaign span.lines:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to bottom, #00ff06, #ff8813);
}

.home-campaign span.move-lines:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #00ff06, #ff8813);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.home-campaign span.move-lines:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #00ff06, #ff8813);
    animation: animate2 2s linear infinite;
    //animation-delay: 1s;
}

@keyframes animate2 {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}

.home-campaign span.move-lines:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to left, #00ff06, #ff8813);
    animation: animate3 2s linear infinite;
}

@keyframes animate3 {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.home-campaign span.move-lines:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(to top, #00ff06, #ff8813);
    animation: animate4 2s linear infinite;
    //animation-delay: 1s;
}

@keyframes animate4 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

@media (max-width:576px) {
    .homeCardTitle{
        text-align: center;
    }
    
}