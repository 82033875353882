// 
// _modal.scss
// 

body[data-layout-mode="dark"] {
    // modal
    .modal-header, .modal-footer{
        border-color: $gray-dark-300;;
    }
}

.modal-dialog.custom {
    min-height: calc(100% - 1rem);
    max-width: 650px;

}

.blur{
    filter: blur(20px);
    pointer-events: none;
    user-select: none;
}