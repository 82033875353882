.termsText {
    margin: 1rem !important;
}

.termsTextBold {
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
}
/* .page-content {
    overflow-x: hidden;
    margin: 0 3rem !important;
} */
